import {
  DECRIMENT_TIMER,
  DEVICE_UPLOAD_FAIL,
  DEVICE_UPLOAD_REQUEST,
  DEVICE_UPLOAD_SUCCESS,
  DOOR_CLOSE_FAIL,
  DOOR_CLOSE_REQUEST,
  DOOR_CLOSE_SUCCESS,
  DOOR_OPEN_FAIL,
  DOOR_OPEN_REQUEST,
  DOOR_OPEN_SUCCESS,
  GET_ALL_DOORS_FAIL,
  GET_ALL_DOORS_REQUEST,
  GET_ALL_DOORS_SUCCESS,
  INCRIMENT_TIMER,
  LiftActionTypes,
} from "./lift.types";
import {Door} from "../../api/api.types";
import {changeFloorStatus, getSectionsPanel} from "../../utils/list.util";

export interface Floor {
  floor: string;
  open: boolean;
  section: string;
  house: string;
  token: number;
  deviceToken: number;
}

export type LiftState = {
  timer: { [key: string]: number };
  floorsA: Floor[];
  floorsB: Floor[];
  errors: string[];
  isLoading: boolean;
  doors: Door[];
  A1: Floor[];
  A2: Floor[];
  A3: Floor[];
  B1: Floor[];
  B2: Floor[];
  B3: Floor[];
};

const generateFloors = (floorNum: number, section: "A" | "B"): Floor[] => {
  const floors: Floor[] = [];

  for (let index = 0; index < floorNum; index++) {
    const floor: Floor = {
      floor: `${index + 1}`,
      open: false,
      section,
      house: "",
      token: 0,
      deviceToken: 0,
    };
    floors.push(floor);
  }

  return floors;
};

const initialState: LiftState = {
  timer: {},
  floorsA: generateFloors(25, "A"),
  floorsB: generateFloors(25, "B"),
  errors: [],
  isLoading: false,
  doors: [],
  A1: [],
  A2: [],
  A3: [],
  B1: [],
  B2: [],
  B3: [],
};

export const liftReducer = (state = initialState, action: LiftActionTypes) => {
  switch (action.type) {
    case DOOR_OPEN_REQUEST:
    case DOOR_CLOSE_REQUEST:
    case GET_ALL_DOORS_REQUEST:
      return { ...state, isLoading: true };

    case DOOR_OPEN_SUCCESS:
      const changedStatus = changeFloorStatus(action.payload.token, true);
      const newTimers = { ...state.timer, [`${action.payload.token}`]: 5 * 60 };

      !action.payload.timer && delete newTimers[action.payload.token];
      return {
        ...state,
        timer: newTimers,
        isLoading: false,
        A1: state.A1.map(changedStatus),
        A2: state.A2.map(changedStatus),
        A3: state.A3.map(changedStatus),
        B1: state.B1.map(changedStatus),
        B2: state.B2.map(changedStatus),
        B3: state.B3.map(changedStatus),
      };

    case DOOR_CLOSE_SUCCESS:
      const changeStatus = changeFloorStatus(action.payload.token, false);
      const timers = { ...state.timer };
      delete timers[action.payload.token];

      return {
        ...state,
        timer: timers,
        isLoading: false,
        A1: state.A1.map(changeStatus),
        A2: state.A2.map(changeStatus),
        A3: state.A3.map(changeStatus),
        B1: state.B1.map(changeStatus),
        B2: state.B2.map(changeStatus),
        B3: state.B3.map(changeStatus),
      };

    case DOOR_OPEN_FAIL:
    case DOOR_CLOSE_FAIL:
      return { ...state, isLoading: false };

    case GET_ALL_DOORS_SUCCESS:
      const { A1, A2, A3, B1, B2, B3 } = getSectionsPanel(action.payload.Door);
      return {
        ...state,
        isLoading: false,
        doors: action.payload.Door,
        floorsA: A1,
        floorsB: B1,
        A1,
        A2,
        A3,
        B1,
        B2,
        B3,
      };

    case GET_ALL_DOORS_FAIL:
      return { ...state, isLoading: false };

    case DECRIMENT_TIMER:
      const timer = action.payload.tokens.map((token) => {
        return [token, state.timer[token] - 1];
      });
      return {
        ...state,
        timer: Object.fromEntries(timer),
      };

    case INCRIMENT_TIMER:
      return { ...state };

    case DEVICE_UPLOAD_REQUEST:
      return { ...state };

    case DEVICE_UPLOAD_SUCCESS:
      // eslint-disable-next-line no-alert
      alert('СКД в ліфті буде перезапущено протягом 3 хвилин');
      return { ...state };

    case DEVICE_UPLOAD_FAIL:
      return { ...state };

    default:
      // eslint-disable-next-line no-case-declarations,@typescript-eslint/no-unused-vars
      const x: never = action;
  }

  return state;
};
