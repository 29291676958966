import {
  DecrimentTimer, DEVICE_UPLOAD_FAIL,
  DEVICE_UPLOAD_REQUEST,
  DEVICE_UPLOAD_SUCCESS,
  DOOR_CLOSE_FAIL,
  DOOR_CLOSE_REQUEST,
  DOOR_CLOSE_SUCCESS,
  DOOR_OPEN_FAIL,
  DOOR_OPEN_REQUEST,
  DOOR_OPEN_SUCCESS,
  GET_ALL_DOORS_FAIL,
  GET_ALL_DOORS_REQUEST,
  GET_ALL_DOORS_SUCCESS,
} from "./lift.types";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../core/root.reducer";
import {LiftActionTypes} from "../lift/lift.types";
import {api} from "../../api/api";
import {DeviceUploadRequestBody, OpenCloseDoorRequestBody} from "../../api/api.types";
import {AuthActionTypes} from "../auth/auth.types";
import {login} from "../auth/auth.action";

export const getLiftPanelFromApi = (token: string, canRelogin = true) => {
  return async (dispatch: ThunkDispatch<AppState, any, LiftActionTypes>, getState: () => AppState): Promise<void> => {
    try {
      console.log("action", token);
      dispatch({ type: GET_ALL_DOORS_REQUEST });
      const response = await api.getDoors(token);
      const Door = response.data.Door.filter((el) => !el.Name.includes("Res"));
      console.log({ response, data: Door });

      dispatch({ type: GET_ALL_DOORS_SUCCESS, payload: { Door } });
    } catch (error) {
      try {
        if (canRelogin) {
          const {
            auth: { credentials, token },
          } = getState();
          dispatch(login(credentials.username, credentials.pass));
          getLiftPanelFromApi(token, false);
        }
      } catch (error) {
        dispatch({ type: GET_ALL_DOORS_FAIL, payload: error });
      }
    }
  };
};

export const openDoor = (data: OpenCloseDoorRequestBody, timer: boolean,  canRelogin = true) => {
  return async (dispatch: ThunkDispatch<AppState, any, LiftActionTypes | AuthActionTypes>, getState: () => AppState): Promise<void> => {
    try {
      dispatch({ type: DOOR_OPEN_REQUEST });
      const response = await api.openDoor(data);
      console.log({ open: response });

      dispatch({
        type: DOOR_OPEN_SUCCESS,
        payload: { token: data.Tokens[0], timer },
      });
    } catch (error) {
      console.log(error);

      try {
        if (canRelogin) {
          const {
            auth: { credentials, token },
          } = getState();
          dispatch(login(credentials.username, credentials.pass));
          data.UserSID = token;
          openDoor(data, timer, false);
        }
      } catch (error) {
        dispatch({ type: DOOR_OPEN_FAIL, payload: error.message });
      }
    }
  };
};

export const closeDoor = (data: OpenCloseDoorRequestBody, canRelogin = true) => {
  return async (dispatch: ThunkDispatch<AppState, any, LiftActionTypes | AuthActionTypes>, getState: () => AppState): Promise<void> => {
    try {
      dispatch({ type: DOOR_CLOSE_REQUEST });
      const response = await api.closeDoor(data);
      console.log({ open: response });

      dispatch({
        type: DOOR_CLOSE_SUCCESS,
        payload: { token: data.Tokens[0] },
      });
    } catch (error) {
      console.log(error);
      try {
        if (canRelogin) {
          const {
            auth: { credentials, token },
          } = getState();
          dispatch(login(credentials.username, credentials.pass));
          data.UserSID = token;
          closeDoor(data, false);
        }
      } catch (error) {
        dispatch({ type: DOOR_CLOSE_FAIL, payload: error.message });
      }
    }
  };
};

export const uploadDevice = (data: DeviceUploadRequestBody) => {
  return async (dispatch: ThunkDispatch<AppState, any, LiftActionTypes>, getState: () => AppState): Promise<void> => {
    try {
      dispatch({type: DEVICE_UPLOAD_REQUEST});
      const response = await api.deviceUpload(data);
      console.log({response});
      dispatch({type: DEVICE_UPLOAD_SUCCESS});
    } catch (error) {
      console.log(error);
      dispatch({type: DEVICE_UPLOAD_FAIL});
    }
  };
};

export const decrimentTime = (tokens: string[]): DecrimentTimer => ({
  type: "DECRIMENT_TIMER",
  payload: { tokens },
});
