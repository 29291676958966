import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import { Build } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { DeviceUploadRequestBody } from '../../api/api.types';
import { AppState } from '../../core/root.reducer';
import { AuthState } from '../../store/auth/auth.reducer';
import { uploadDevice } from '../../store/lift/lift.action';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';


interface Props {
  deviceToken: number;
  liftName: string;
}

export const UploadConfigurationButton: FC<Props> = ({ deviceToken, liftName }: Props) => {
  const dispatch = useDispatch();
  const { token: UserSID, credentials } = useSelector<AppState, AuthState>(state => state.auth);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [reason, setReason] = React.useState<string>('');


  const showDialog = (): void => {
    setOpenDialog(true);
  };

  const hideDialog = (): void => {
    setOpenDialog(false);
  };

  const handleConfigUpload = (): void => {
    hideDialog();
    const data: DeviceUploadRequestBody = {
      Tokens: [deviceToken],
      UserSID,
      Comment: `Користувач: ${credentials.username}\nЛіфт: ${liftName}\nПричина: ${reason}`,
    };
    dispatch(uploadDevice(data));
    setReason('');
  };

  const suggestReason = (event: React.MouseEvent<HTMLLIElement, MouseEvent>): void => {
    setReason(event.currentTarget.innerText);
  };

  return (
    <>
      <Button
        size="small"
        onClick={showDialog}
      >
        <Build /> Перезапустити СКД
      </Button>
      <Dialog
        open={openDialog}
        onClose={hideDialog}
      >
        <DialogTitle>Перезапуск ліфта {liftName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Якщо СКД в ліфті працює некореткно, перезапуск може допомогти.
            Будь ласка, коротко вкажіть причину перезапуску. Наприклад:
            <ul>
              <li onClick={suggestReason}>Зчитувач блимає жовтим і пускає всіх без ключа</li>
              <li onClick={suggestReason}>Ліфт НЕ дає вибрати жодний поверх з будь-яким ключем, в тому числі з ключем охорони</li>
              <li onClick={suggestReason}>Не спрацьовували ключі квартири ___ (вкажіть номер)</li>
              <li>Інша причина</li>
            </ul>
          </DialogContentText>
          <TextField
              autoFocus
              required
              margin="dense"
              id="reason"
            name="reason"
            label="Вкажіть причину"
            type="text"
            fullWidth
            variant="standard"
            helperText="Не менше 20 сиволів"
            multiline
            error={reason.length < 20}
            value={reason}
            onChange={(e): void => setReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={hideDialog}>Відмінити</Button>
          <Button disabled={reason.length < 20} onClick={handleConfigUpload}>Перезапустити</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
